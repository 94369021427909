/* Plugin styles */
.social-feed-container {
    -moz-column-width: 30%;
    -webkit-column-width: 30%;
    -moz-column-gap: 1.6rem;
    -webkit-column-gap: 1.6rem; 
    column-width: 30.0rem;
    column-gap: 1.6rem;
    
}
.social-feed-element.hidden{
    background-color:red !important;
}
.social-feed-element .pull-left{
    float:left;
    margin-right: 10px;
}
.social-feed-element .pull-right {
    margin-left: 10px;
}
.social-feed-element img {
    width: 100%;
    width: auto\9;
    height: auto;
    border: 0;  
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}
.social-feed-element .attachment {
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}

/* Link styles */
.social-feed-element a {
    color: #0088cc;
    text-decoration: none;
}
.social-feed-element a:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.social-feed-element a:hover,
.social-feed-element a:active {
    outline: 0;
    color: #005580;
    text-decoration: underline;
}

/* Text styles */
.social-feed-element small {
    font-size: 85%;
}
.social-feed-element strong {
    font-weight: bold;
}
.social-feed-element em {
    font-style: italic;
}
.social-feed-element p {
    margin: 0 0 10px;
}
.social-feed-element .media-body > p{
    margin-bottom:4px;
    min-height:20px;
}
.social-feed-element p.social-feed-text {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.social-feed-element .content:after {
    content: " ";
    display: block;
    clear: both;
}
/* Message styles */
.social-feed-element,
.social-feed-element .media-body {
    overflow: hidden;
    zoom: 1;
    *overflow: visible;
}
.social-feed-element .media-body {
    display: block;
    float: left;
    width: calc( 100% - 58px);
}
.social-feed-element .media-body .social-network-icon{
    margin-top: -3px;
    margin-right:5px;
    width:16px;
}
.social-feed-element .media-body div{
    color:#666;
    line-height: 20px;
}
.social-feed-element:first-child {
    /*margin-top: 0;*/
}
.social-feed-element .media-object {
    display: block;
    width:48px;
    border-radius:50%;
}
.social-feed-element .media-heading {
    margin: 0 0 5px;
}
.social-feed-element .media-list {
    margin-left: 0;
    list-style: none;
}

.social-feed-element .muted {
    color: #999;
}
.social-feed-element a.muted:hover,
.social-feed-element a.muted:focus {
    color: #808080;
}

.social-feed-element{
    background-color: #EEEEEE;
    transition: 0.25s;
    /*-webkit-backface-visibility: hidden;*/
    margin: .8rem -1px;
    
    color: #333;
    text-align:left;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    -webkit-break-inside: avoid;
    -moz-break-inside: avoid;
    break-inside: avoid;
    -moz-column-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
}
.social-feed-element:hover{
    background-color: #D0D0D0;
}

.social-feed-element .content{
    padding:15px;
}
.social-feed-element .social-network-icon{
    opacity:0.7;
}


.social-feed-element .author-title{
    color: #444;
    line-height: 1.5em;
    font-weight: 500;  
}